import Base = require("Everlaw/Base");
import DateUtil = require("Everlaw/DateUtil");
import { TimezoneN } from "Everlaw/DateUtil";
import { CustodianDirectory, CustodianDirectoryId } from "Everlaw/LegalHold/CustodianDirectory";
import { ComboableField } from "Everlaw/LegalHold/CustodianImport/CustodianImportField";
import { getProjectDateDisplayFormat } from "Everlaw/ProjectDateUtil";

/**
 * @author Jamie Gaskin
 */

export class Custodian extends Base.Object {
    /**
     * Timezone to use when displaying {@link employeeStartDate} or {@link employeeEndDate}
     * timestamps as a date. Should correspond to the constant of the same name in the backend.
     */
    static readonly EMPLOYEE_DATE_DISPLAY_TZ = "UTC" as TimezoneN;

    get className() {
        return "Custodian";
    }

    override id: CustodianId;
    directoryId: CustodianDirectoryId;
    created: number;
    deletedTimestamp: number;

    // Editable fields
    name: string;
    employeeId: string;
    email: string;
    otherEmails: string;
    businessPhone: string;
    mobilePhone: string;
    faxNumber: string;
    companyName: string;
    role: string;
    department: string;
    officeLocation: string;
    managerName: string;
    managerEmail: string;
    employeeType: string;
    employeeStartDate: number;
    employeeEndDate: number;
    preferredLanguage: string;
    notes: string;
    status: Status;
    // Start of fields specific to custodians from Microsoft
    userPrincipalName: string;
    displayName: string;
    preferredDataLocation: string;

    constructor(params: CustodianJson) {
        super(params);
        this._mixin(params);
    }

    override _mixin(params: CustodianJson) {
        Object.assign(this, params);
    }

    override display() {
        return this.name || this.email || this.employeeId;
    }

    getCustodianFieldValue(field: CustodianField): string {
        const value = this[field];
        if (!value) {
            return "";
        }

        if (isDateField(field)) {
            return timestampToDate(value as number);
        }

        return value as string;
    }
}

export type CustodianId = number & Base.Id<"Custodian">;

// Define a utility type to exclude methods
type NonMethodKeys<T> = {
    // eslint-disable-next-line @typescript-eslint/ban-types
    [K in keyof T]: T[K] extends Function ? never : K;
}[keyof T];

// Define CustodianJson type with only properties
export type CustodianJson = Partial<Pick<Custodian, NonMethodKeys<Custodian>>>;

/**
 * Maps enum {@link #CustodianField} to values, used for sending requests to the backend
 */
export type CustodianFieldJson = Partial<
    Record<CustodianField | ComboableField, string | number | null>
>;

// Should match Status in Custodian.java
export enum Status {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    UNDETERMINED = "UNDETERMINED",
}

export function timestampToDate(timestamp: number): string {
    return DateUtil.displayFullDateWithFormat(
        DateUtil.asDate(timestamp),
        getProjectDateDisplayFormat(),
        false,
    );
}

// Should match MergeType in CustodianService.java
export enum MergeType {
    SKIP = "SKIP",
    CREATE_NEW = "CREATE_NEW",
    OVERWRITE_WITH_VALUE = "OVERWRITE_WITH_VALUE",
    OVERWRITE_ALL = "OVERWRITE_ALL",
}

/**
 * Corresponds to the editable fields defined above.
 */
export enum CustodianField {
    NAME = "name",
    EMPLOYEE_ID = "employeeId",
    EMAIL = "email",
    OTHER_EMAILS = "otherEmails",
    BUSINESS_PHONE = "businessPhone",
    MOBILE_PHONE = "mobilePhone",
    FAX_NUMBER = "faxNumber",
    PREFERRED_LANGUAGE = "preferredLanguage",
    COMPANY_NAME = "companyName",
    ROLE = "role",
    DEPARTMENT = "department",
    OFFICE_LOCATION = "officeLocation",
    MANAGER_NAME = "managerName",
    MANAGER_EMAIL = "managerEmail",
    EMPLOYEE_TYPE = "employeeType",
    EMPLOYEE_START_DATE = "employeeStartDate",
    EMPLOYEE_END_DATE = "employeeEndDate",
    NOTES = "notes",
    DELETED_TIMESTAMP = "deletedTimestamp",
    // microsoft fields
    DISPLAY_NAME = "displayName",
    PREFERRED_DATA_LOCATION = "preferredDataLocation",
}

export const DISPLAYABLE_CUSTODIAN_FIELDS = Object.keys(CustodianField).map(
    (key: string) => CustodianField[key as keyof typeof CustodianField],
);

// Fields in the CustodianTable that are not directly on the Custodian
export enum AltCustodianTableField {
    CUSTODIAN_FLAGS = "flags",
}

export const CustodianTableField = { ...CustodianField, ...AltCustodianTableField };
export type CustodianTableField = CustodianField | AltCustodianTableField;

/**
 * Array of CustodianFields that are present in static directories by default.
 */
export const DEFAULT_CUSTODIAN_FIELDS = [
    CustodianField.NAME,
    CustodianField.EMPLOYEE_ID,
    CustodianField.EMAIL,
    CustodianField.OTHER_EMAILS,
    CustodianField.BUSINESS_PHONE,
    CustodianField.MOBILE_PHONE,
    CustodianField.FAX_NUMBER,
    CustodianField.PREFERRED_LANGUAGE,
    CustodianField.COMPANY_NAME,
    CustodianField.ROLE,
    CustodianField.DEPARTMENT,
    CustodianField.OFFICE_LOCATION,
    CustodianField.MANAGER_NAME,
    CustodianField.MANAGER_EMAIL,
    CustodianField.EMPLOYEE_TYPE,
    CustodianField.EMPLOYEE_START_DATE,
    CustodianField.EMPLOYEE_END_DATE,
    CustodianField.NOTES,
];

/**
 * Custodian Fields that represent the basic information of a custodian.
 */
export const CUSTODIAN_BASIC_FIELDS = [
    CustodianField.NAME,
    CustodianField.EMPLOYEE_ID,
    CustodianField.EMAIL,
    CustodianField.OTHER_EMAILS,
    CustodianField.BUSINESS_PHONE,
    CustodianField.MOBILE_PHONE,
    CustodianField.FAX_NUMBER,
    CustodianField.PREFERRED_LANGUAGE,
    // microsoft fields
    CustodianField.DISPLAY_NAME,
    CustodianField.PREFERRED_DATA_LOCATION,
];

/**
 * Custodian Fields that represent the company information of a custodian.
 */
export const CUSTODIAN_COMPANY_FIELDS = [
    CustodianField.COMPANY_NAME,
    CustodianField.ROLE,
    CustodianField.DEPARTMENT,
    CustodianField.OFFICE_LOCATION,
    CustodianField.MANAGER_NAME,
    CustodianField.MANAGER_EMAIL,
    CustodianField.EMPLOYEE_TYPE,
    CustodianField.EMPLOYEE_START_DATE,
    CustodianField.EMPLOYEE_END_DATE,
];

/**
 * How custodian fields should be displayed on the webpage.
 */
export const CUSTODIAN_FIELD_DISPLAY: Record<CustodianField, string> = {
    [CustodianField.NAME]: "Name",
    [CustodianField.EMPLOYEE_ID]: "Employee ID",
    [CustodianField.EMAIL]: "Email",
    [CustodianField.OTHER_EMAILS]: "Other emails",
    [CustodianField.BUSINESS_PHONE]: "Business phone",
    [CustodianField.MOBILE_PHONE]: "Mobile phone",
    [CustodianField.FAX_NUMBER]: "Fax number",
    [CustodianField.PREFERRED_LANGUAGE]: "Preferred language",
    [CustodianField.COMPANY_NAME]: "Company name",
    [CustodianField.ROLE]: "Role",
    [CustodianField.DEPARTMENT]: "Department",
    [CustodianField.OFFICE_LOCATION]: "Office location",
    [CustodianField.MANAGER_NAME]: "Manager name",
    [CustodianField.MANAGER_EMAIL]: "Manager email",
    [CustodianField.EMPLOYEE_TYPE]: "Employee type",
    [CustodianField.EMPLOYEE_START_DATE]: "Employee start date",
    [CustodianField.EMPLOYEE_END_DATE]: "Employee end date",
    [CustodianField.NOTES]: "Notes",
    [CustodianField.DELETED_TIMESTAMP]: "Date removed",
    // microsoft fields
    [CustodianField.DISPLAY_NAME]: "Display name",
    [CustodianField.PREFERRED_DATA_LOCATION]: "Preferred data location",
};

export const CUSTODIAN_FIELD_ENUM_REPRESENTATION: Record<CustodianField, string> = {
    [CustodianField.NAME]: "NAME",
    [CustodianField.EMAIL]: "EMAIL",
    [CustodianField.EMPLOYEE_ID]: "EMPLOYEE_ID",
    [CustodianField.OTHER_EMAILS]: "OTHER_EMAILS",
    [CustodianField.BUSINESS_PHONE]: "BUSINESS_PHONE",
    [CustodianField.MOBILE_PHONE]: "MOBILE_PHONE",
    [CustodianField.FAX_NUMBER]: "FAX_NUMBER",
    [CustodianField.PREFERRED_LANGUAGE]: "PREFERRED_LANGUAGE",
    [CustodianField.COMPANY_NAME]: "COMPANY_NAME",
    [CustodianField.ROLE]: "ROLE",
    [CustodianField.DEPARTMENT]: "DEPARTMENT",
    [CustodianField.OFFICE_LOCATION]: "OFFICE_LOCATION",
    [CustodianField.MANAGER_NAME]: "MANAGER_NAME",
    [CustodianField.MANAGER_EMAIL]: "MANAGER_EMAIL",
    [CustodianField.EMPLOYEE_TYPE]: "EMPLOYEE_TYPE",
    [CustodianField.EMPLOYEE_START_DATE]: "EMPLOYEE_START_DATE",
    [CustodianField.EMPLOYEE_END_DATE]: "EMPLOYEE_END_DATE",
    [CustodianField.NOTES]: "NOTES",
    [CustodianField.DELETED_TIMESTAMP]: "DELETED_TIMESTAMP",
    // microsoft fields
    [CustodianField.DISPLAY_NAME]: "DISPLAY_NAME",
    [CustodianField.PREFERRED_DATA_LOCATION]: "PREFERRED_DATA_LOCATION",
};

export const ALT_CUSTODIAN_TABLE_FIELD_DISPLAY: Record<AltCustodianTableField, string> = {
    [AltCustodianTableField.CUSTODIAN_FLAGS]: "Flags",
};

export function isCustodianField(value: string): value is CustodianField {
    return Object.values(CustodianField).includes(value as CustodianField);
}

export function displayField(field: CustodianField): string {
    return CUSTODIAN_FIELD_DISPLAY[field];
}

export function displayTableField(field: CustodianTableField): string {
    return isCustodianField(field) ? displayField(field) : ALT_CUSTODIAN_TABLE_FIELD_DISPLAY[field];
}

export function displayFieldValue(field: CustodianField, value: string | number): string {
    if (!value) {
        return "";
    }
    if (
        field === CustodianField.EMPLOYEE_START_DATE
        || field === CustodianField.EMPLOYEE_END_DATE
    ) {
        return timestampToDate(value as number);
    }
    return value as string;
}

export function isDateField(field: CustodianField): boolean {
    return (
        field === CustodianField.EMPLOYEE_START_DATE || field === CustodianField.EMPLOYEE_END_DATE
    );
}

/**
 * Return the maximum allowed length for the given field. Corresponds to the maximum lengths in
 * the PSQL table `Custodian`.
 */
export function getCustodianFieldMaxLength(field: CustodianField): number {
    switch (field) {
        case CustodianField.BUSINESS_PHONE:
        case CustodianField.MOBILE_PHONE:
        case CustodianField.FAX_NUMBER:
            return 64;
        case CustodianField.EMPLOYEE_START_DATE:
        case CustodianField.EMPLOYEE_END_DATE:
        case CustodianField.DELETED_TIMESTAMP:
            // Date type fields are stored as a BIGINT, but the value here represents the human-readable
            // date format (e.g. MM/DD/YYYY); 32 is an arbitrary but sufficiently long max length
            return 32;
        case CustodianField.EMPLOYEE_TYPE:
        case CustodianField.PREFERRED_LANGUAGE:
            return 64;
        case CustodianField.EMAIL:
        case CustodianField.MANAGER_EMAIL:
            return 100;
        case CustodianField.NAME:
        case CustodianField.MANAGER_NAME:
        case CustodianField.EMPLOYEE_ID:
        case CustodianField.DEPARTMENT:
        case CustodianField.COMPANY_NAME:
            return 128;
        case CustodianField.OFFICE_LOCATION:
        case CustodianField.OTHER_EMAILS:
        case CustodianField.ROLE:
            return 256;
        case CustodianField.NOTES:
            return 512;
        // microsoft fields
        case CustodianField.DISPLAY_NAME:
        case CustodianField.PREFERRED_DATA_LOCATION:
            return 128;
    }
}

/**
 * Whether a custodian is static (i.e. editable directly on the Everlaw platform). A custodian
 * is static if any of the following is true:
 * <li>
 *     <ul>It belongs to a static directory</ul>
 *     <ul>It belongs to a dynamic directory which is disconnected</ul>
 *     <ul>It belongs to a dynamic directory but has an Inactive status</ul>
 * </li>
 */
export function isCustodianStatic(custodian: Custodian): boolean {
    const directory = Base.get(CustodianDirectory, custodian.directoryId);
    if (!directory.isMicrosoftDirectory() || !directory.connected) {
        return true;
    }
    return custodian.status === Status.INACTIVE;
}
