import Base = require("Everlaw/Base");
import { CustodianField } from "Everlaw/LegalHold/Custodian";
import { IdentifierType } from "Everlaw/LegalHold/CustodianConflict";
import { OrganizationId } from "Everlaw/MinimalOrganization";

export const CUSTODIAN_DIRECTORY_NAME_MAX_LENGTH = 255;

/**
 * Frontend version of enum SyncStatus in MicrosoftCustodianDirectory.java
 */
export enum SyncStatus {
    SYNCING = "SYNCING",
    RESYNCING = "RESYNCING",
    UPDATING = "UPDATING",
    SYNCED = "SYNCED",
    UNSYNCED = "UNSYNCED",
}

/**
 * Frontend version of enum CustodianRetentionPolicy in MicrosoftCustodianDirectory.java
 */
export enum CustodianRetentionPolicy {
    RETAIN_ALL = "RETAIN_ALL",
    RETAIN_CUSTODIANS_ON_HOLD = "RETAIN_CUSTODIANS_ON_HOLD",
}

export class CustodianDirectory extends Base.Object {
    override id: CustodianDirectoryId;
    name: string;
    orgId: OrganizationId;
    viewableCustodianFields: CustodianField[];
    // microsoft custodian directory specific fields
    tenantId: string;
    microsoftOrgName: string;
    connected: boolean;
    syncStatus: SyncStatus;
    mergeDupCustodians: boolean;
    custodianDupIdentifier: IdentifierType;
    custodianRetentionPolicy: CustodianRetentionPolicy;
    sendNotificationOnDisconnect: boolean;
    notificationRecipients: string[];
    lastSyncedTimestamp: number | null = null;
    lastDisconnectedTimestamp: number | null = null;

    get className(): string {
        return "CustodianDirectory";
    }

    constructor(params: Record<string, unknown>) {
        super(params);
        this._mixin(params);
    }

    override _mixin(params: Record<string, unknown>) {
        const { viewableCustodianFields, ...rest } = params;
        Object.assign(this, rest);
        this.viewableCustodianFields = (viewableCustodianFields as string[]).map(
            (field: string) => {
                if (field in CustodianField) {
                    return CustodianField[field as keyof typeof CustodianField];
                } else {
                    return field as CustodianField;
                }
            },
        );
    }

    override display(): string {
        return this.name;
    }

    isMicrosoftDirectory(): boolean {
        // every Microsoft directory will have a microsoft org name
        return !!this.microsoftOrgName;
    }

    /**
     * If directory is in syncing state.
     * <br>
     * The directory is in unsynced but connected status without a lastSyncedTimestamp before it
     * begins syncing. From the perspective of the frontend, that's still syncing.
     */
    isSyncing(): boolean {
        return (
            this.isMicrosoftDirectory()
            && (this.syncStatus === SyncStatus.SYNCING
                || (this.syncStatus === SyncStatus.UNSYNCED
                    && this.connected
                    && !this.lastSyncedTimestamp))
        );
    }
}

export type CustodianDirectoryId = number & Base.Id<"CustodianDirectoryId">;
